import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDS2M7YLE4vqJD1r1Bspf0UYZ_PweykJ2w",
  authDomain: "invoice-app-yt-345a9.firebaseapp.com",
  projectId: "invoice-app-yt-345a9",
  storageBucket: "invoice-app-yt-345a9.appspot.com",
  messagingSenderId: "932363320682",
  appId: "1:932363320682:web:12518d28020111f9c6f913",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export default firebaseApp.firestore();
